<template>
  <v-container fluid style="height: 100%; display: flex; flex-direction: column">
    <page-title
      title="Layout Templates"
      subtitle="Browse and manage layout templates!"
      :badge="this.total"
    />
    <v-row class="mt-0 mb-1" justify="space-between">
      <v-col>
        <v-btn color="info" @click="add"><i class="fal fa-plus mr-2"></i>Add Layout Template</v-btn>
      </v-col>
      <v-col cols="12" sm="12" md="9" lg="8">
        <div class="d-flex flex-row align-center justify-end flex-wrap" style="gap: 0.5rem">
          <filter-manager
            ref="filterManager"
            v-model="selectedFilters"
            :options.sync="options"
          ></filter-manager>
          <v-sheet height="28" width="1" color="blue-grey lighten-4" class="mx-1"></v-sheet>
          <columns-visibility-control
            :defaults="defaultHeaders"
            :withColumnsOrder="true"
            v-model="headers"
            :storageKey="storageKey"
          />
          <v-text-field
            v-model="options.search"
            label="Search"
            ref="mainSearch"
            class="table-search-field"
            @focus="mainSearchInFocus = true"
            @blur="mainSearchInFocus = false"
            :style="{
              'max-width':
                mainSearchInFocus || (options.search != '' && options.search != null)
                  ? '200px'
                  : '110px',
            }"
            dense
            solo
            hide-details
            clearable
            prepend-inner-icon="far fa-search"
            :loading="loadingStates.table"
          >
          </v-text-field>
          <refresh :loading="loadingStates.table" @refresh="getDataDebounced()"></refresh>
        </div>
      </v-col>
    </v-row>
    <v-data-table
      style="
        overflow: auto;
        overflow: overlay;
        height: 100%;
        flex: 1 1 auto;
        display: flex;
        flex-direction: column;
      "
      dense
      :headers="tableHeader"
      :items="entities"
      :options.sync="options"
      :server-items-length="total"
      :items-per-page="options.itemsPerPage"
      :loading="loadingStates.table"
      @click:row="rowClicked"
      class="elevation-2 templates-table table-sticky-header-exclude-footer"
      :footer-props="{
        showFirstLastPage: true,
        firstIcon: 'far fa-arrow-to-left',
        lastIcon: 'far fa-arrow-to-right',
        prevIcon: 'far fa-angle-left',
        nextIcon: 'far fa-angle-right',
        itemsPerPageOptions: [15, 30, 50, 100],
      }"
    >
      <template v-slot:progress>
        <v-progress-linear color="blue" absolute indeterminate></v-progress-linear>
      </template>

      <template v-slot:[`item.imageUrl`]="{ item }">
        <v-badge color="secondary" bordered left offset-x="20px" offset-y="34px">
          <template v-slot:badge>
            <i class="fal fa-drafting-compass mb-0" style="font-size: 10px"></i>
          </template>
          <v-tooltip right z-index="999" nudge-right="-4px">
            <template v-slot:activator="{ on, attrs }">
              <div v-bind="attrs" v-on="on">
                <div class="space-img">
                  <div
                    style="height: 100%; width: 100%"
                    v-viewer
                    @click.stop
                    v-if="item.imageUrl != null && item.imageUrl != ''"
                  >
                    <img :key="item.id + '_img'" :src="item.imageUrl" height="100%" width="100%" />
                  </div>
                  <i v-else :class="'fad fa-drafting-compass'"></i>
                </div>
              </div>
            </template>
            <span>
              <v-img
                v-if="(item.imageUrl != null) & (item.imageUrl != '')"
                :src="item.imageUrl"
                height="250px"
                width="250px"
                contain
              ></v-img>
              <i
                v-else
                :class="'fad fa-drafting-compass'"
                style="font-size: 32px; padding: 2rem"
              ></i>
            </span>
          </v-tooltip>
        </v-badge>
      </template>

      <template v-slot:[`item.name`]="{ item }">
        <strong>{{ item.name }}</strong>
        <p class="ma-0" v-if="item.description != null && item.description != ''">
          {{ item.description }}
        </p>
      </template>

      <template v-slot:[`item.createdBy`]="{ item }">
        <user-avatar :user="item.createdBy" icon></user-avatar>
      </template>

      <template v-slot:[`item.updatedBy`]="{ item }">
        <user-avatar :user="item.updatedBy" icon></user-avatar>
      </template>

      <template v-slot:[`item.createDate`]="{ item }">
        <dater :date="item.createDate" date-only></dater>
      </template>

      <template v-slot:[`item.updateDate`]="{ item }">
        <dater :date="item.updateDate" date-only></dater>
      </template>

      <template v-slot:[`item.viewsCount`]="{ item }">
        <equipment-count :count="getViewsCount(item)" />
      </template>

      <template v-slot:[`item.actions`]="{ item }">
        <v-menu dense offset-x right>
          <template v-slot:activator="{ attrs, on }">
            <v-btn icon elevation="0" v-bind="attrs" v-on="on">
              <i class="far fa-ellipsis-v" style="font-size: 16px"></i>
            </v-btn>
          </template>
          <v-list class="more-options-menu">
            <v-list-item @click="update(item.id)" v-if="$has(perms.LayoutTemplates.Update)">
              <v-list-item-icon class="mr-2 justify-center">
                <v-icon small>fal fa-pen</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>Edit</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-list-item @click="del(item)" v-if="$has(perms.LayoutTemplates.Delete)">
              <v-list-item-icon class="mr-2 justify-center">
                <v-icon small>fal fa-trash-alt red--text</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title class="red--text">Delete</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-subheader
              class="font-weight-bold"
              style="height: auto"
              v-if="!$has(perms.LayoutTemplates.Update) && !$has(perms.LayoutTemplates.Delete)"
            >
              No Actions Available!
            </v-subheader>
          </v-list>
        </v-menu>
      </template>

      <template v-slot:no-data>
        <img width="500" src="/img/art/fogg-no-connection-2.png" />
        <p class="font-weight-bold">No Data Available!</p>
      </template>
      <template v-slot:loading>
        <video width="250" muted loop autoplay style="padding: 0.5rem">
          <source src="/img/art/astronaut-loves-music-4980476-4153140.mp4" type="video/mp4" />
        </video>
        <p class="font-weight-bold">Searching the Cosmos...</p>
      </template>
    </v-data-table>

    <edit-layout-template
      ref="editLayoutTemplate"
      @save="onTemplateSaved"
      @delete="onDeleteLayoutTemplateSuccess"
      @close="onTemplatePanelClosed"
    ></edit-layout-template>
  </v-container>
</template>

<script>
import perms from "../../../plugins/permissions";
import EditLayoutTemplate from "../components/EditLayoutTemplate.vue";
import layoutTemplateService from "../services/layoutTemplates-service";
import layoutTemplateHeader from "../config/tables/layoutTemplate.header";
import FilterManager from "../../Shared/components/FilterManager/FilterManager.vue";
import { UserFilterSettings } from "../../Shared/components/FilterManager/FilterSettings";

export default {
  components: { EditLayoutTemplate, FilterManager },
  data() {
    return {
      perms: perms,
      storageKey: "LayoutTemplates",
      openLayoutTemplateInEditMode: false,
      selectedFilters: [],
      paramId: null,
      entities: [],
      selected: {},
      total: 0,
      valid: false,
      mainSearchInFocus: false,
      options: {
        id: null,
        search: null,
        sortBy: ["createDate"],
        sortDesc: [true],
        createdByIds: [],
        updatedByIds: [],
        ClientId: [],
      },
      loadingStates: {
        table: false,
      },
      headers: layoutTemplateHeader,
      defaultHeaders: [],
    };
  },
  computed: {
    tableHeader() {
      return this.headers.filter((elm) => !elm.hidden);
    },
  },
  created() {
    this.defaultHeaders = this.headers;
    if (this.$route.query.q) this.options.search = this.$route.query.q;
    if (this.storageKey) {
      this.options.itemsPerPage = +this.$getFromLocal(
        `${this.storageKey}-itemsPerPage`,
        false,
        this.options.itemsPerPage || 15
      );
    }
  },
  mounted() {
    this.initFilterManagerSettings();
    document.querySelector("main.v-main").classList.add("sticky-main-fix");
    //this.$refs.mainSearch.focus();
    this.checkRouteQuery();
    this.checkForSingleTemplateRoute(this.$route);
  },
  beforeDestroy() {
    document.querySelector("main.v-main").classList.remove("sticky-main-fix");
  },
  methods: {
    initFilterManagerSettings() {
      var createdByFilterSettings = new UserFilterSettings();
      createdByFilterSettings.title = "Created By";
      createdByFilterSettings.model = "createdByIds";
      createdByFilterSettings.ref = "createdByFilter";

      var updatedByFilterSettings = new UserFilterSettings();
      updatedByFilterSettings.title = "Updated By";
      updatedByFilterSettings.model = "updatedByIds";
      updatedByFilterSettings.ref = "updatedByFilter";

      this.selectedFilters = [createdByFilterSettings, updatedByFilterSettings];
    },
    checkRouteQuery(evt) {
      // this.$log("checkRouteQuery", this.$route.query.q, evt);
      if (this.$route.query.q) this.options.search = this.$route.query.q;
      else this.options.search = null;
    },
    getViewsCount(item) {
      if (item != null && item.views != null) return item.views.length;
      else return 0;
    },
    focus() {
      //this.$refs.mainSearch.focus();
    },
    getData() {
      this.$backToTop(0, document.querySelector(".templates-table .v-data-table__wrapper"));
      this.loadingStates.table = true;
      layoutTemplateService
        .query(this.$clean(this.options, true))
        .then((resp) => {
          this.entities = resp.data.items;
          this.$log("getData Success > this.entities", this.entities);
          this.total = resp.data.total;
          this.loadingStates.table = false;
        })
        .catch((err) => {
          this.loadingStates.table = false;
          this.$handleError(err);
        });
    },
    getDataDebounced() {
      if (this.layoutTemplatesTimerId == null) {
        this.layoutTemplatesTimerId = -1;
        this.updateRouterLink();
        this.getData();
        return;
      }
      // cancel pending call
      clearTimeout(this.layoutTemplatesTimerId);

      // delay new call 400ms
      this.layoutTemplatesTimerId = setTimeout(() => {
        this.updateRouterLink();
        this.getData();
      }, 400);
    },
    updateRouterLink() {
      if (this.$route.query.q == this.options.search) return;
      if (this.options.search != null && this.options.search != "") {
        this.$router.push({
          path: "/layout-templates",
          query: { q: this.options.search },
          replace: true,
        });
      } else this.$router.push({ path: "/layout-templates", replace: true });
    },
    del(template) {
      this.$dialog
        .warning({
          text: ` Are you sure you want to delete this layout template?<br/><h4>${template.name}</h4>`,
          title: `Delete Layout Template?`,
          color: "error",
          persistent: true,
          actions: {
            false: {
              text: "Cancel",
            },
            true: {
              text: "Confirm",
              color: "error",
              handle: () => {
                return layoutTemplateService
                  .delete(template.id)
                  .then((resp) => {
                    this.onDeleteLayoutTemplateSuccess(template.id);
                    this.$dialog.notify.success("Layout Template deleted successfully", {
                      position: "top-right",
                      timeout: 3000,
                    });
                  })
                  .catch((resp) => {
                    this.$dialog.notify.error("Error deleting the Layout Template!", {
                      position: "top-right",
                      timeout: 3000,
                    });
                  });
              },
            },
          },
        })
        .then((res) => {});
    },
    onDeleteLayoutTemplateSuccess(id) {
      const index = this.entities.indexOf(this.entities.filter((e) => e.id == id)[0]);
      if (index > -1) {
        this.entities.splice(index, 1);
      }
    },
    onTemplateSaved(templateData, isNewTemplate) {
      this.$log("onTemplateSaved", templateData, isNewTemplate);
      if (isNewTemplate) {
        this.addToArr(this.entities, templateData, true);
      } else {
        this.updateArr(this.entities, templateData);
      }
    },
    onTemplatePanelClosed() {
      // this.$log(">> onTemplatePanelClosed");
      setTimeout(() => {
        const path = `/layout-templates`;
        if (this.$route.path !== path) this.$router.push(path);
        document.title = this.$route.meta.title + " | Concordia";
      });
    },
    openLayoutTemplate(id) {
      // this.$log("openLayoutTemplate =>>>", id, this.openLayoutTemplateInEditMode);
      setTimeout(() => {
        this.$refs.editLayoutTemplate.open(id, this.openLayoutTemplateInEditMode);
        this.openLayoutTemplateInEditMode = false;
      }, 50);
    },
    add() {
      setTimeout(() => {
        this.$refs.editLayoutTemplate.open(null);
      });
    },
    update(id) {
      this.selected = { id: id };
      this.openLayoutTemplateInEditMode = true;
      setTimeout(() => {
        const path = `/layout-templates/${id}`;
        if (this.$route.path !== path) this.$router.push(path);
      });
    },
    view(id) {
      this.openLayoutTemplateInEditMode = false;
      setTimeout(() => {
        const path = `/layout-templates/${id}`;
        if (this.$route.path !== path) this.$router.push(path);
      });
    },
    rowClicked(row) {
      this.view(row.id);
    },
    displayStats(item) {
      this.$log("displayStats", item);
      this.selected = JSON.parse(
        JSON.stringify({ ...this.entities.filter((e) => e.id == item.id)[0] })
      );
      setTimeout(() => {
        this.$refs.statesTemplate.open(item);
      });
    },
    checkForSingleTemplateRoute(route) {
      // this.$log("checkForSingleTemsplateRoute", route);
      if (route.params && route.params.id) {
        this.paramId = Number.parseInt(route.params.id);
        this.openLayoutTemplate(this.paramId);
      } else this.paramId = null;
    },
  },
  watch: {
    options: {
      handler(val) {
        this.$setToLocal(`${this.storageKey}-itemsPerPage`, val.itemsPerPage);
        this.getDataDebounced();
      },
      deep: true,
    },
    $route: {
      handler(newRoute, oldRoute) {
        this.checkRouteQuery();
        // this.$log("Layout TEMPLATES - oldRoute", oldRoute, "newRoute", newRoute);

        //from layout template list view TO => single layout template view,
        if (oldRoute.name == "layout-templates" && newRoute.name == "layout-template") {
          this.openLayoutTemplate(newRoute.params.id);
          return;
        }

        //from single layout template view TO => single layout template view,
        if (oldRoute.name == "layout-template" && newRoute.name == "layout-template") {
          this.openLayoutTemplate(newRoute.params.id);
          return;
        }
      },
      deep: true,
    },
  },
};
</script>

<style lang="scss">
.templates-table {
  tbody tr:not(.v-data-table__empty-wrapper) {
    cursor: pointer;
  }
}
</style>
